import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";
import escapeRegExp from "lodash-es/escapeRegExp";
import { fromError } from "stacktrace-js";
import { v4 as uuidv4 } from "uuid";

import * as m from "@bokio/mobile-web-shared/core/model/model";
import { getCurrentCountryIso } from "@bokio/shared/utils/culture";

import type { ITelemetryItem } from "@microsoft/applicationinsights-web";

const gaDimensions: Record<string, string | number> = {};

export const urlsToExclude = [
	"https://bat.bing.com",
	"https://snap.licdn.com/li.lms-analytics/insight.min.js",
	"https://snap.licdn.com/li.lms-analytics/insight.old.min.js",
	"https://cdn.linkedin.oribi.io",
	"https://px.ads.linkedin.com",
	"https://snap.licdn.com/",
	"https://px.ads.linkedin.com/wa/",
	"https://cdn.taboola.com/libtrc/unip/",
	"https://trc.taboola.com/1540701/",
	"https://trc-events.taboola.com/1540701/",
	"https://psb.taboola.com/topics_api",
	"https://13039426.fls.doubleclick.net",
	"https://ad.doubleclick.net",
	"https://google.com/pagead/",
	"https://static2.creative-serving.com",
	"https://ads.creative-serving.com/",
	"https://static2.creative-serving.org/",
	"https://app.vwo.com",
	"https://dev.visualwebsiteoptimizer.com/",
];

const excludedUrlPatterns: RegExp[] = urlsToExclude.map(url => {
	const escapedUrl = escapeRegExp(url);

	return new RegExp(`^${escapedUrl}`);
});

const AppInsights = new ApplicationInsights({
	config: {
		instrumentationKey: window.config.applicationInsights.instrumentationKey,
		disableCookiesUsage: window.doNotTrackStatus === "Track" ? false : true,
		/* ...Other Configuration Options... */
	},
});

export const filterApplicationInsights = (envelope: ITelemetryItem) => {
	const data = envelope?.baseData;
	if (data) {
		if (data.url) {
			const urlToCheck = data.url;
			for (const pattern of excludedUrlPatterns) {
				if (pattern.test(urlToCheck)) {
					return false;
				}
			}
		}

		if (data.target) {
			const targetToCheck = data.target;
			for (const pattern of excludedUrlPatterns) {
				if (pattern.test(targetToCheck)) {
					return false;
				}
			}
		}
	}
	return true;
};

if (window.config.applicationInsights.instrumentationKey) {
	AppInsights.loadAppInsights();
	AppInsights.addTelemetryInitializer(filterApplicationInsights);
}

const getErrorWithStackTrace = async (error: Error) => {
	try {
		const stackFrames = await fromError(error);
		const stringifiedStack = stackFrames.map(sf => sf.toString()).join("\n");
		if (error && stackFrames.length > 0) {
			error.stack = stringifiedStack;
		}
		return error;
	} catch {
		return error;
	}
};

// Override window.onerror to be able to get custom stack trace for better exception logging
const appInsightsOnError = window.onerror;
window.onerror = async (
	message: Event | string,
	url?: string,
	lineNumber?: number,
	columnNumber?: number,
	error?: Error,
) => {
	const myError = error ? await getErrorWithStackTrace(error) : undefined;
	appInsightsOnError && appInsightsOnError(message, url, lineNumber, columnNumber, myError);
};

const toDictionary = (info: object): Record<string, string> =>
	Object.entries(info).reduce((dict, entry) => ({ ...dict, [entry[0]]: entry[1] }), {});

function stripGuids(path: string) {
	return path.replace(/\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/gi, "");
}

function getTrackingProperties() {
	return {
		releaseVersion: window.releaseInfo?.version || "",
		releaseDate: window.releaseInfo?.date || "",
		currentUrl: window?.location?.href || "", // ME: OperationName is not correct in AI so we add this to be able to get the page
	};
}

export type EventCategory =
	| "Referral"
	| "Help"
	| "HelpMessage"
	| "DismissAutomaticInvoiceBanner"
	| "Factoring"
	| "Page Error"
	| "BankImport"
	| "Promotion"
	| "Tooltip"
	| "CustomiseInvoice"
	| "Pagination"
	| "AddOns"
	| "ProductTour"
	| "StarterGuide"
	| "Demo"
	| "SignUpSurvey"
	| "UserPermissions"
	| "FinancialOverview"
	| "ResourcesHelpPage"
	| "ImportBookkeeping"
	| "BookkeepingMetric"
	| "InvoicesReportChart"
	| "Employee"
	| "EmployeeVacation"
	| "Payroll"
	| "ClosurePackageSelection"
	| "ContextualBankTransactions"
	| "BookkeepingFlow"
	| "Menu"
	| "exp2_Menu"
	| "Integrations"
	| "ImportSi"
	| "AssetTemplate"
	| "PeriodOrRangeSelector"
	| "Bank"
	| "BokioPlan"
	| "BokioBusinessAccount"
	| "FBE_reconcile"
	| "Agency"
	| "YearEnd"
	| "SupplierInvoicePaymentMethodFailFrontendCheck"
	| "NPS"
	| "What's new"
	| "SettingsSearch"
	| "anonymous_ab"
	| "TestDataGenerated"
	| "Signup"
	| "Drilldown"
	| "ECommerce"
	| "ReceiptMatching"
	| "Todo"
	| "ETR"
	| "ETRRecord"
	| "ETRRecordStart"
	| "ETRRecordAction"
	| "Support";

function gaEvent(
	category: EventCategory,
	action: string,
	label?: string,
	value?: number,
	eventParameters?: object,
	eventId?: string,
) {
	if (window.gtag) {
		window.gtag("event", "app_event", {
			category,
			action,
			label,
			value,
			...eventParameters,
			...gaDimensions,
			eventID: eventId,
		});
	}
}

function gaPageError(error: Error, handledAt: string, info: object) {
	try {
		const stats = {
			location: window.location.pathname,
			handledAt,
			error,
			info,
			...getTrackingProperties(),
		};
		gaEvent("Page Error", stripGuids(window.location.pathname), JSON.stringify(stats));
	} catch (err) {}
}

function aiPageView(path?: string) {
	try {
		AppInsights.trackPageView({ uri: path, properties: getTrackingProperties() });
	} catch (err) {}
}

export function aiEvent(eventName: EventCategory, properties: Record<string, unknown>) {
	try {
		AppInsights.trackEvent({ name: eventName, properties: { ...properties, ...getTrackingProperties() } });
	} catch (err) {}
}

function aiPageError(error: Error, handledAt: string, info: object) {
	try {
		AppInsights.trackException({
			exception: error,
			properties: { handledAt, ...toDictionary(info), ...getTrackingProperties() },
		});
	} catch (err) {}
}

export async function trackException(error: Error, handledAt: string, info: object) {
	const errorWithStackTrace = await getErrorWithStackTrace(error);
	gaPageError(errorWithStackTrace, handledAt, info);
	aiPageError(errorWithStackTrace, handledAt, info);
}

export const trackError = (error: unknown, handledAt: string, info: object) => {
	if (error instanceof Error) {
		trackException(error, handledAt, { ...info });
	} else {
		trackException(new Error(), handledAt, { ...info, error: JSON.stringify(error) });
	}
};

export function cleanQueryString(queryString?: string) {
	if (!!queryString) {
		const params = new URLSearchParams(queryString);
		const paramsToKeep: { key: string; value: string }[] = [];
		const allowedLongParams = new Set<string>(["utm_source"]);
		params.forEach((value, key) => {
			if (value?.length <= 12 || allowedLongParams.has(key)) {
				//ME: We keep these short query params because we often do tracking on them. This should exclude guids and secrets
				paramsToKeep.push({ key, value });
			}
		});

		if (!paramsToKeep.length) {
			return "";
		}
		return "?" + paramsToKeep.map(p => (p.value ? `${p.key}=${p.value}` : p.key)).join("&");
	} else {
		return "";
	}
}

export function trackPageView(path?: string, queryString?: string) {
	if (window?.doNotTrackStatus === "Track") {
		const query = cleanQueryString(queryString);
		const eventId = uuidv4();

		gaPageView(eventId, path + query);
		aiPageView(path + query);
		fbPageView(eventId, path + query);
	}
}

export function trackEvent(
	category: EventCategory,
	action: string,
	label?: string,
	value?: number,
	eventParamaters?: object,
	eventId?: string,
) {
	if (window?.doNotTrackStatus === "Track") {
		gaEvent(category, action, label, value, eventParamaters, eventId);
		if (category !== "anonymous_ab") {
			//Only used to create GA segments
			aiEvent(category, { ...getTrackingProperties(), action, label, value, ...eventParamaters });
		}
	}
}

type FacebookPixelEventName =
	| "AddPaymentInfo"
	| "AddToCart"
	| "AddToWishlist"
	| "CompleteRegistration"
	| "Contact"
	| "CustomizeProduct"
	| "Donate"
	| "FindLocation"
	| "InitiateCheckout"
	| "Lead"
	| "Purchase"
	| "Schedule"
	| "Search"
	| "StartTrial"
	| "SubmitApplication"
	| "Subscribe"
	| "ViewContent"
	| "BBAStartedActivation"
	| "BBASignedAgreement";

/** This is it's own event because we only track track specific events to FB https://developers.facebook.com/docs/meta-pixel/reference */
export const trackEventFB = (
	eventName: FacebookPixelEventName,
	parameters:
		| facebook.Pixel.ViewContentParameters
		| facebook.Pixel.SearchParameters
		| facebook.Pixel.AddToCartParameters
		| facebook.Pixel.AddToWishlistParameters
		| facebook.Pixel.InitiateCheckoutParameters
		| facebook.Pixel.AddPaymentInfoParameters
		| facebook.Pixel.PurchaseParameters
		| facebook.Pixel.LeadParameters
		| facebook.Pixel.CompleteRegistrationParameters,
	eventId: facebook.Pixel.EventIDOptions,
	custom: boolean | undefined = false,
) => {
	//If we expand this usage we likely want to add typing to this: https://www.npmjs.com/package/@types/facebook-pixel
	const track = custom ? "trackCustom" : "track";
	if (
		window.fbq &&
		typeof window.fbq === "function" &&
		window?.doNotTrackStatus === "Track" &&
		!!window.config.metaPixel.Id
	) {
		try {
			window.fbq(track, eventName, parameters, eventId);
		} catch (err) {}
	}
};

const FBWhiteList = ["/dashboard", "/todo", "/uploads", "/reports", "/invoicing", "/sales", "/expenses"];

function fbPageView(eventId: string, path?: string) {
	const name = stripGuids(path || window.location.pathname);
	const isTrackable = FBWhiteList.some(pathName => name.includes(pathName));
	if (window.fbq && typeof window.fbq === "function" && !!window.config.metaPixel.Id && isTrackable) {
		trackEventFB("ViewContent", { content_name: name }, { eventID: eventId });
	}
}

export const trackEventMSFT = (
	eventCategory: string,
	eventAction: string,
	eventLabelOrParameters?: string | object,
	eventParameters?: object,
) => {
	if (window.uetq && window?.doNotTrackStatus === "Track") {
		let eventLabel, parameters;
		if (typeof eventLabelOrParameters === "string") {
			eventLabel = eventLabelOrParameters;
			parameters = eventParameters;
		} else {
			parameters = eventLabelOrParameters;
		}
		window.uetq.push("event", eventAction, {
			event_category: eventCategory,
			event_label: eventLabel,
			...parameters,
		});
	}
};

export enum LinkedinTrackingEvent {
	StartRegistration = 11144050,
	CompleteRegistration = 11144058,
	StartTestCompany = 11144066,
	StartTrial = 11144074,
	SelectedPlanFree = 11144178,
	SelectedPlanPremium = 11144186,
	SelectedPlanPro = 11144194,
	BBAStartedActivation = 14411922,
	BBASignedAgreement = 14411930,
}

export function getLinkedInPlan(plan: m.Entities.BokioPlan): LinkedinTrackingEvent {
	switch (plan) {
		case "Free":
			return LinkedinTrackingEvent.SelectedPlanFree;
		case "Premium":
			return LinkedinTrackingEvent.SelectedPlanPremium;
		case "Pro":
			return LinkedinTrackingEvent.SelectedPlanPro;
		default:
			throw new Error(`Invalid plan: ${plan}`);
	}
}

export const trackEventLinkedIn = (trackingEvent: LinkedinTrackingEvent) => {
	if (window.lintrk && window?.doNotTrackStatus === "Track") {
		window.lintrk("track", { conversion_id: trackingEvent });
	}
};

export const trackEventTaboolaPixel = (eventName: string, eventParameters?: object) => {
	if (window._tfa && window?.doNotTrackStatus === "Track") {
		const params = eventParameters || {};
		window._tfa.push({ notify: "event", name: eventName, id: window.config.taboolaPixel.partnerId, ...params });
	}
};

export type TiktokTrackingData = {
	contents: (object | undefined)[];
	value?: string;
	currency?: string;
};

export const trackEventTikTokPixel = (
	eventName: string,
	eventParameters?: object,
	value?: string,
	currency?: string,
) => {
	if (window.ttq && window?.doNotTrackStatus === "Track") {
		const trackingData: TiktokTrackingData = {
			contents: [eventParameters],
		};
		if (value) {
			trackingData.value = value;
		}
		if (currency) {
			trackingData.currency = currency;
		}
		window.ttq.track(eventName, trackingData);
	}
};

function getSeverityLevel(type?: "info" | "warn" | "error"): SeverityLevel | undefined {
	switch (type) {
		case "info":
			return SeverityLevel.Information;
		case "warn":
			return SeverityLevel.Warning;
		case "error":
			return SeverityLevel.Error;
		default:
			return undefined;
	}
}

export function trackTrace(message: string, properties?: { [name: string]: string }, type?: "info" | "warn" | "error") {
	try {
		const severity = getSeverityLevel(type);
		AppInsights.trackTrace({
			message: message,
			severityLevel: severity,
			properties: { ...properties, ...getTrackingProperties() },
		});
	} catch (err) {}
}

export function setUserId(userId: string) {
	if (window.gtag) {
		window.gtag("config", window.config.ga.measurementId, {
			user_id: userId,
			send_page_view: false,
		});
	}
	try {
		AppInsights.setAuthenticatedUserContext(userId);
	} catch (err) {}
}

export function setUserIdAndDimensions(company: m.Core.CompanyInfo, userId: string) {
	if (window.gtag) {
		window.gtag("config", window.config.ga.measurementId, {
			user_id: userId,
			custom_map: {
				dimension1: "userId",
				dimension2: "accountingMethod",
				dimension3: "companyType",
				dimension4: "companyId",
				// dimension5: {{Existing User}} (0 or 1) which is no longer used. See: https://dev.azure.com/bokiodev/Voder/_git/Voder/pullrequest/15664
			},
			send_page_view: false,
		});

		gaDimensions["userId"] = userId;
		gaDimensions["accountingMethod"] = company.ActiveAccountingMethod;
		gaDimensions["companyType"] = company.CompanyType;
		gaDimensions["companyId"] = company.Id;
	}
	try {
		AppInsights.setAuthenticatedUserContext(userId);
	} catch (err) {}
}

interface TrackConversionOptions {
	value?: number;
	currency?: string;
	transaction_id?: string;
	event_callback?: () => void;
}

export const GaConversionTrackingEvent = {
	StartedPurchasePlan: {
		SE: "SxtWCP3vnqMYEL_O2K8D",
		UK: "b8YSCLmt_KUYEP7Wj70C",
	},
	CompletedPurchasePlan: {
		SE: "jmSUCOnvnosYEL_O2K8D",
		UK: "SxITCOPQrKAYEP7Wj70C",
	},
	PremiumPlusPlan: {
		SE: "UngfCO-EurYYEL_O2K8D",
		UK: "",
	},
	StartTrial: {
		SE: "oTVYCObvnosYEL_O2K8D",
		UK: "ID7dCITh450YEP7Wj70C",
	},
	CompletedRegistration: {
		SE: "jWbvCOPvnosYEL_O2K8D",
		UK: "10ONCJ_n450YEP7Wj70C",
	},
	BBAStartedActivation: {
		SE: "EDl3CN6y-KUYEL_O2K8D",
		UK: "",
	},
	BBASignedAgreement: {
		SE: "E_C1COGy-KUYEL_O2K8D",
		UK: "",
	},
};

interface TrackConversionOptionsGa extends TrackConversionOptions {
	send_to: string;
}

export function trackConversion(
	conversionEvent: { SE: string; UK: string },
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	options: TrackConversionOptions = {} as TrackConversionOptions,
) {
	const conversion_id = window?.config?.ga?.conversionId;
	const iso = getCurrentCountryIso();
	const country = iso === "gb" ? "UK" : "SE";
	const currency = iso === "gb" ? "GBP" : "SEK";
	const conversionEventId = conversionEvent[country];
	if (window.gtag && conversion_id) {
		const opts: TrackConversionOptionsGa = Object.assign(options, {
			send_to: `${conversion_id}/${conversionEventId}`,
			currency: currency,
		});
		window.gtag("event", "conversion", opts);
	}
}
export enum DV360TrackingEvent {
	PageView = "bokio0/bokio0+unique",
	CompletedRegistration = "bokio0/bokio00+unique",
	StartTrial = "bokio0/bokio000+unique",
	CompletedPurchasePlan = "bokio0/bokio001+unique",
	StartRegistration = "bokio0/bokio003+unique",
	BBAStartedActivation = "bokio00/bokio0+unique",
	BBASignedAgreement = "bokio00/bokio00+unique",
	PremiumPlusPlan = "bokio0/bokio005+unique",
}

export const trackEventDV360 = (trackingEvent: DV360TrackingEvent) => {
	const dv360_id = window?.config?.ga?.dv360Id;
	if (window.gtag && dv360_id) {
		window.gtag("event", "conversion", {
			allow_custom_scripts: true,
			send_to: `${dv360_id}/${trackingEvent}`,
		});
	}
};

function gaPageView(eventId: string, path?: string) {
	const name = stripGuids(path || window.location.pathname);
	if (window.gtag) {
		window.gtag("event", "virtual_page_view", { page_location_stripped: name, eventID: eventId, ...gaDimensions });
		window.gtag("event", "page_view", { page_location_stripped: name, eventID: eventId, ...gaDimensions });
		trackEventDV360(DV360TrackingEvent.PageView);
		trackEventTaboolaPixel("page_view");
		trackEventTikTokPixel("PageView", { content_id: name });
	}
}

export enum TrackingPlatform {
	Facebook = "Facebook",
	Microsoft = "Microsoft",
	GA4 = "GA4",
	Linkedin = "Linkedin",
	Taboola = "Taboola",
	Gads = "Gads",
	DV360 = "DV360",
}

const trackingPremiumValue = {
	Facebook: 5102,
	Microsoft: 5102,
	GA4: 5102,
	Linkedin: 5102,
	Taboola: 5102,
	Gads: 3265,
	DV360: 3265,
};
const trackingPremiumPlusValue = {
	Facebook: 11737,
	Microsoft: 11737,
	GA4: 11737,
	Linkedin: 11737,
	Taboola: 11737,
	Gads: 6441,
	DV360: 6441,
};

export function completePurchasePlanValue(plan: m.Entities.BokioPlan, platform: TrackingPlatform) {
	switch (plan) {
		case m.Entities.BokioPlan.Premium:
			return trackingPremiumValue[platform];
		case m.Entities.BokioPlan.Pro:
			return trackingPremiumPlusValue[platform];
		default:
			return 0;
	}
}
