import * as qs from "qs";

import { denormalizeData, normalizeResponse } from "@bokio/mobile-web-shared/services/api/client/normalize";
import { Config } from "@bokio/shared/config";
import { deepCopy } from "@bokio/utils/deepCopy";

import { appMiddleware } from "./AppMiddleware";
import {
	getClientBrowserPlugins,
	getClientId,
	getClientLocalIP,
	getDoNotTrackFlag,
	getScreen,
	getTimezoneOffsetInMinutes,
	getWindowSize,
} from "./fingerprinting";
import { fromJson, toQueryString, withData } from "./helpers";

import type { IClient } from "@bokio/mobile-web-shared/services/api/client";

const BASE_URL = Config.env.apiUrl;

interface StringMap {
	[k: string]: string;
}

export interface GetOptions {
	// eslint-disable-next-line @typescript-eslint/no-empty-object-type
	queryData: {};
	headers: StringMap;
}

export interface BodyRequestOptions {
	// eslint-disable-next-line @typescript-eslint/no-empty-object-type
	data: {};
	isFormData: boolean;
	headers: StringMap;
}

const defaultOptions: GetOptions & BodyRequestOptions = {
	queryData: {},
	data: {},
	isFormData: false,
	headers: {},
};

export const auth = async (path: string, data: object = {}) => {
	const options = {
		method: "POST",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
		},
		body: qs.stringify(data),
	};

	return appMiddleware.fetch(BASE_URL + path, options).then(fromJson);
};

export class WebClient implements IClient {
	async _delete(path: string, data: object = {}) {
		return this.request("DELETE", this.getPathWithQueryString(path, data));
	}

	async get(path: string, queryData: object = {}) {
		return this.getWithOptions(path, { queryData });
	}

	async getFingerprintHeaders() {
		const headers: { [h: string]: string } = {};
		const trySetHeader = (name: string, valueFunc: () => string | undefined) => {
			try {
				const value = valueFunc();
				if (value !== undefined) {
					headers[name] = value;
				}
			} catch (error) {}
		};

		trySetHeader("X-JS-User-Agent", () => window.navigator.userAgent);
		trySetHeader("X-JS-Do-Not-Track", getDoNotTrackFlag);
		trySetHeader("X-Client-UtcOffsetMinutes", getTimezoneOffsetInMinutes);
		trySetHeader("X-Client-Window-Size", getWindowSize);
		trySetHeader("X-Client-Screen", getScreen);
		trySetHeader("X-Client-Device-Id", getClientId);
		trySetHeader("X-Client-Timestamp", () => new Date().toISOString());
		trySetHeader("X-Client-Browser-Plugins", getClientBrowserPlugins);

		try {
			const localIP = await getClientLocalIP();
			trySetHeader("X-Client-Local-IPs", () => localIP);
		} catch (error) {}

		return headers;
	}

	getPathWithQueryString(path: string, query?: object) {
		return (
			path +
			(query && Object.keys(query).length > 0
				? (path.includes("?") ? "&" : "?") + toQueryString(denormalizeData(deepCopy(query)))
				: "")
		);
	}

	async getWithOptions(path: string, options: Partial<GetOptions> = {}) {
		const { queryData } = options;
		return this.request("GET", this.getPathWithQueryString(path, queryData), options);
	}

	async post(path: string, data: object = {}, isFormData = false) {
		return this.request("POST", path, { data, isFormData });
	}

	async postWithOptions(path: string, options: Partial<BodyRequestOptions> = {}) {
		return this.request("POST", path, options);
	}

	async put(path: string, data: object = {}, isFormData = false) {
		return this.request("PUT", path, { data, isFormData });
	}

	async request(method: string, path: string, options: Partial<GetOptions | BodyRequestOptions> = {}) {
		const optionsWithDefaults = {
			...defaultOptions,
			...options,
		};
		const { data, headers, isFormData } = optionsWithDefaults;
		const fetchOptions = withData(method, data, isFormData);

		// make IE stop caching stuff like crazy
		fetchOptions.headers["If-Modified-Since"] = "Mon, 26 Jul 1997 05:00:00 GMT";
		// extra
		fetchOptions.headers["Cache-Control"] = "no-cache";
		fetchOptions.headers.Pragma = "no-cache";

		fetchOptions.headers = {
			...fetchOptions.headers,
			...headers,
		};

		return appMiddleware
			.fetch(BASE_URL + path, fetchOptions)
			.then(fromJson)
			.then(normalizeResponse);
	}
}
